<template>
  <v-card :color="theme ? '#2F2F2F' :'#E7E5E5'" >

    <v-card-title> 
      <v-spacer></v-spacer>
      <span class="font-weight-bold">
        Account Activation
      </span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-card-text>
    <v-card-text class="" v-else>
      <div class="d-flex justify-center" v-if="success">
        <v-icon size="40px" color="success"> mdi-check-circle</v-icon>
        <span class="success--text my-5"> Verification Successful. Please Log in here </span>
      </div>
      <div v-else class="d-flex justify-center">
        <v-icon color="error">mdi-alert-circle</v-icon>
        <span class="red--text ml-2 my-2" v-for="err of errors" :key="err.code">
          {{ err.message }}
        </span>
      </div>
    </v-card-text>

    <v-card-actions  class="py-6 nevermind-light" :class="{'nevermind-dark': theme }">
        <v-spacer></v-spacer>
        <router-link to='/auth/login' class="ml-2">Sign in </router-link>
        <v-spacer></v-spacer>
      </v-card-actions>
  </v-card>
</template>


<script>
import colorMxn from '@/mixins/color_mixin';
import formMxn from '@/mixins/form_mixin';
import gql from "graphql-tag";

export default {
  name: 'AccountActivation',
  mixins: [colorMxn, formMxn],
  data() {
    return {
      errors : {},
      success : false,
      loading: false,
    }
  },
  mounted() {
    this.activate();
  },
  methods: {
    activate() {
      this.loading = true;
      let data_ = {
        verifyAccountToken: this.$route.params.token,
      };

      let ACTIVATION_QUERY = gql`mutation VerifyAccountMutations($verifyAccountToken: String!) {
        verifyAccount(token: $verifyAccountToken) {
          success
          errors
        }
      }`;
      this.$apollo.mutate({
        mutation: ACTIVATION_QUERY,
        variables: data_,
        update: (cache, { data }) => {
          this.loading = false;
          this.errors = data.verifyAccount.errors ? data.verifyAccount.errors.nonFieldErrors : ['Invalid Token'] ;
          this.success = data.verifyAccount.success;
        }
      })
    }
  },
}
</script>
